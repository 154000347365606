// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import SplitPayments from "../../blocks/SplitPayments/src/SplitPayments";
import GovtPortalIntegrationLeasingPlatform from "../../blocks/GovtPortalIntegrationLeasingPlatform/src/GovtPortalIntegrationLeasingPlatform";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import AdminConsole2 from "../../blocks/AdminConsole2/src/AdminConsole2";
import Payments from "../../blocks/Payments/src/Payments";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import GovtPortalIntegrationForFetchingTenantsDetails from "../../blocks/GovtPortalIntegrationForFetchingTenantsDetails/src/GovtPortalIntegrationForFetchingTenantsDetails";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Analytics from "../../blocks/analytics/src/Analytics";
import FormApprovalWorkflow from "../../blocks/FormApprovalWorkflow/src/FormApprovalWorkflow";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import RecurringPayments2 from "../../blocks/RecurringPayments2/src/RecurringPayments2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import GovtPortalIntegrationForEnsuringPayments from "../../blocks/GovtPortalIntegrationForEnsuringPayments/src/GovtPortalIntegrationForEnsuringPayments";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";



const routeMap = {
SplitPayments:{
 component:SplitPayments,
path:"/SplitPayments"},
GovtPortalIntegrationLeasingPlatform:{
 component:GovtPortalIntegrationLeasingPlatform,
path:"/GovtPortalIntegrationLeasingPlatform"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
AdminConsole2:{
 component:AdminConsole2,
path:"/AdminConsole2"},
Payments:{
 component:Payments,
path:"/Payments"},
PaymentAdmin2:{
 component:PaymentAdmin2,
path:"/PaymentAdmin2"},
GovtPortalIntegrationForFetchingTenantsDetails:{
 component:GovtPortalIntegrationForFetchingTenantsDetails,
path:"/GovtPortalIntegrationForFetchingTenantsDetails"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
FormApprovalWorkflow:{
 component:FormApprovalWorkflow,
path:"/FormApprovalWorkflow"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
RecurringPayments2:{
 component:RecurringPayments2,
path:"/RecurringPayments2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
GovtPortalIntegrationForEnsuringPayments:{
 component:GovtPortalIntegrationForEnsuringPayments,
path:"/GovtPortalIntegrationForEnsuringPayments"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;